import { createBatch, createId, getFirestoreDoc, getFirestoreDocs, updateFirestoreDoc, firestoreIncrement, firestoreDeleteField, firebaseTimestamp } from '@/firebase/firestore'
import { deleteImageFromStorage } from '@/firebase/storage'
import { logAnalyticsEvent } from '@/firebase/analytics'
import { tracePerformance } from '@/firebase/performance'
import { getUid } from '@/firebase/auth'
import useNotifications from '@/composables/useNotifications'
import { createTagsSumsData } from '@/helpers/tagsSums'
import localizeFilter from '@/filters/localize.filter'
import Currencies from '@/libraries/currencies'

export default {
  state: {
    transactions: {},
    clickedTransactionId: null,
    transactionsReset: 0,
    transactionsFilters: {
      section: null,
      dateStart: null,
      dateEnd: null,
      bankId: null,
      accountId: null,
      cardId: null,
      currencyCode: null,
      sumStart: null,
      sumEnd: null,
      tags: [],
      noTags: false,
      useTags: 'all',
      searchStr: ''
    },
    transactionsSettings: {
      dateStart: null,
      dateEnd: null,
      sumStart: null,
      sumEnd: null,
      accountId: null,
      tags: []
    },
    transactionsSort: {
      field: 'Date',
      direction: 'desc'
    },
    transactionsShow: {
      add: false,
      edit: null,
      delete: false,
      filters: false,
      transactionsSettings: false,
      addTag: false,
      receipt: false,
      addReceipt: false
    },
    lastFetchedTransaction: null,
    transactionsMinDate: null,
    transactionsMaxDate: null,
    deletingTransactions: []
  },
  mutations: {
    addTransactionToStore(state, { transactionId, data }) {
      if (transactionId && data) {
        state.transactions[transactionId] = data
      }
    },
    updateTransactionInStore(state, { transactionId, data }) {
      if (transactionId && data) {
        const transaction = { ...state.transactions[transactionId] }

        for (const key of Object.keys(data)) {
          transaction[key] = data[key]

          if (transaction.date) {
            transaction.date = new Date(transaction.date)
          }
          if (transaction.timestamp) { transaction.timestamp = new Date(transaction.timestamp) }
          if (transaction.tags && !transaction.tags.length) { delete transaction.tags }
        }

        state.transactions[transactionId] = transaction
      }
    },
    deleteTransactionFromStore(state, transactionId) {
      if (transactionId) {
        if (state.transactions[transactionId]) { delete state.transactions[transactionId] }

        if (state.deletingTransactions.length && state.deletingTransactions.includes(transactionId)) {
          state.deletingTransactions = state.deletingTransactions.filter(transactionId => {
            return transactionId !== transactionId
          })
        }
      }
    },
    setClickedTransactionId(state, transactionId) {
      state.clickedTransactionId = transactionId
    },
    resetTransactions(state) {
      state.transactionsReset = Date.now()
    },
    setTransactionsFilters(state, filters) {
      state.transactionsFilters = filters
    },
    setTransactionsFiltersSection(state, section) {
      state.transactionsFilters.section = section
    },
    setTransactionsFiltersSearchStr(state, searchStr) {
      state.transactionsFilters.searchStr = searchStr
    },
    setTransactionsSettings(state, settings) {
      state.transactionsSettings = settings
    },
    setTransactionsSortDirection(state, direction) {
      state.transactionsSort.direction = direction
    },
    setTransactionsSortField(state, field) {
      state.transactionsSort.field = field
    },
    setLastFetchedTransaction(state, transaction) {
      state.lastFetchedTransaction = transaction
    },
    setTransactionsMinDate(state, date) {
      state.transactionsMinDate = date
    },
    setTransactionsMaxDate(state, date) {
      state.transactionsMaxDate = date
    },
    setTransactionsShowField(state, show) {
      if (show && Object.keys(show).length) {
        for (const field of Object.keys(show)) {
          state.transactionsShow[field] = show[field]
        }
      }
    },
    setDeletingTransactions(state, transactionId) {
      if (transactionId && !state.deletingTransactions.includes(transactionId)) {
        state.deletingTransactions.push(transactionId)
      }
    },
    clearTransactions(state) {
      state.transactions = {}
    },
    setClearTransactionsFilters(state) {
      state.transactionsFilters = {
        section: null,
        dateStart: null,
        dateEnd: null,
        bankId: null,
        accountId: null,
        cardId: null,
        currencyCode: null,
        sumStart: null,
        sumEnd: null,
        tags: [],
        noTags: false,
        useTags: 'all',
        searchStr: ''
      }
    },
    clearTransactionsOneFilter(state, filter) {
      if (filter) {
        const filters = state.transactionsFilters

        if (filter === 'Date') {
          filters.dateStart = null
          filters.dateEnd = null
        } else if (filter === 'Sum') {
          filters.sumStart = null
          filters.sumEnd = null
        } else if (filter === 'Account') {
          filters.bankId = null
          filters.accountId = null
          filters.cardId = null
          filters.currencyCode = null
        } else if (filter === 'Tags') {
          filters.tags = []
          filters.noTags = false
          filters.useTags = 'all'
        }

        state.transactionsFilters = filters
      }
    },
    clearTransactionsSettings(state) {
      state.transactionsSettings = {
        dateStart: null,
        dateEnd: null,
        sumStart: null,
        sumEnd: null,
        accountId: null,
        tags: []
      }
    },
    clearTransactionsSort(state) {
      state.transactionsSort = {
        field: 'Date',
        direction: 'desc'
      }
    },
    clearTransactionsShow(state) {
      state.transactionsShow = {
        add: false,
        edit: null,
        delete: false,
        filters: false,
        transactionsSettings: false,
        addTag: false,
        receipt: false,
        addReceipt: false
      }
    },
    clearInfo(state) {
      state.transactions = {}
      state.clickedTransactionId = null
      state.transactionsFilters = {
        section: null,
        dateStart: null,
        dateEnd: null,
        bankId: null,
        accountId: null,
        cardId: null,
        currencyCode: null,
        sumStart: null,
        sumEnd: null,
        tags: [],
        noTags: false,
        useTags: 'all',
        searchStr: ''
      }
      state.transactionsSettings = {
        dateStart: null,
        dateEnd: null,
        sumStart: null,
        sumEnd: null,
        accountId: null,
        tags: []
      }
      state.transactionsSort = {
        field: 'Date',
        direction: 'desc'
      }
      state.transactionsShow = {
        add: false,
        edit: null,
        delete: false,
        filters: false,
        transactionsSettings: false,
        addTag: false,
        receipt: false,
        addReceipt: false
      }
      state.lastFetchedTransaction = null
      state.transactionsMinDate = null
      state.transactionsMaxDate = null
      state.deletingTransactions = []
    }
  },
  actions: {
    async fetchTransactions({ commit, dispatch, getters }) {
      if (getters.lessonStep) { return }
      if (getters.loadedAll('transactions')) { return }
      if (!getters.canFetchTransactions) { return }

      const t = tracePerformance('fetchTransactions')
      t.start()

      let maxLimit = 5
      const tarifLimit = await getters.getLimit('fetchTransactions')
      if (tarifLimit) { maxLimit = tarifLimit }

      try {
        const request = {
          maxLimit,
          wheres: [['owner', '==', getUid()]],
          order: []
        }

        if (getters.canUseLimit('setTransactionsSettings', false)) {
          const transactionsSettings = await getters.transactionsSettings

          if (transactionsSettings.dateStart || transactionsSettings.dateEnd) {
            const createUtcDate = (originalDate, addDays = 0) => {
              if (!originalDate) { return new Date() }

              const date = new Date(originalDate)
              const utcDate = new Date()
              utcDate.setUTCFullYear(date.getFullYear())
              utcDate.setUTCMonth(date.getMonth())
              utcDate.setUTCDate(date.getDate() + addDays)
              utcDate.setUTCHours(0)
              utcDate.setUTCMinutes(0)
              utcDate.setUTCSeconds(0)
              utcDate.setMilliseconds(0)

              return utcDate
            }

            if (transactionsSettings.dateStart || transactionsSettings.dateEnd) {
              if (transactionsSettings.dateStart) {
                const utcDateStart = createUtcDate(transactionsSettings.dateStart, 0)
                request.wheres.push(['date', '>=', firebaseTimestamp.fromDate(utcDateStart)])
              }

              if (transactionsSettings.dateEnd) {
                const utcDateEnd = createUtcDate(transactionsSettings.dateEnd, 1)
                request.wheres.push(['date', '<', firebaseTimestamp.fromDate(utcDateEnd)])
              }
            }
          }

          if (transactionsSettings.sumStart !== null || transactionsSettings.sumEnd !== null) {
            if (transactionsSettings.sumStart === transactionsSettings.sumEnd) {
              request.wheres.push(['sum', '==', transactionsSettings.sumStart * 100])
            } else {
              if (transactionsSettings.sumStart !== null) {
                request.wheres.push(['sum', '>=', transactionsSettings.sumStart * 100])
              }

              if (transactionsSettings.sumEnd !== null) {
                request.wheres.push(['sum', '<=', transactionsSettings.sumEnd * 100])
              }

              request.order.push(['sum', 'desc'])
            }
          }

          if (transactionsSettings.accountId) {
            request.wheres.push(['accountId', '==', transactionsSettings.accountId])
          }

          if (transactionsSettings.tags.length) {
            request.wheres.push(['tags', 'array-contains-any', transactionsSettings.tags])
          }
        }

        request.order.push(['date', 'desc'])
        request.order.push(['timestamp', 'desc'])

        const lastFetchedTransaction = await getters.lastFetchedTransaction
        if (lastFetchedTransaction) {
          request.start = lastFetchedTransaction
        }

        const transactions = await getFirestoreDocs({
          collectionName: 'transactions',
          ...request,
          needs: ['rawDoc']
        })

        if (transactions) {
          for (const transaction of transactions) {
            if (transaction.data.transferId && !getters.transactions[transaction.data.transferId]) {
              await dispatch('fetchTransaction', transaction.data.transferId)
            }

            await dispatch('transactionAdded', {
              transactionId: transaction.id,
              data: transaction.data,
              increaseTotalNumber: 0
            })
          }

          await commit('setLastFetchedTransaction', transactions[transactions.length - 1].rawDoc)

          if (transactions.length < maxLimit) {
            await commit('setLoadedAll', { field: 'transactions', value: true })
          }

          await commit('resetTransactions')
        } else {
          await commit('setLoadedAll', { field: 'transactions', value: true })
        }
        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchTransactions', params: {} })
        return false
      } finally {
        t.stop()
      }
    },
    async fetchTransaction({ commit, dispatch, getters }, transactionId) {
      if (getters.lessonStep) { return }

      const t = tracePerformance('fetchTransaction')
      t.start()

      try {
        const data = await getFirestoreDoc('transactions', transactionId)

        if (data) {
          await dispatch('transactionAdded', { transactionId, data, increaseTotalNumber: 0 })
          await commit('resetTransactions')
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchTransaction', params: { transactionId } })
      } finally {
        t.stop()
      }
    },
    async fetchDataForTransaction({ dispatch, getters }, { accountId, cardId, tags, transactionId }) {
      if (getters.lessonStep) { return }
      if (!accountId && !cardId && (!tags || !Array.isArray(tags) || !tags.length)) { return }

      const t = tracePerformance('fetchDataForTransaction')
      t.start()

      try {
        if (accountId) {
          if (!getters.accounts[accountId]) {
            await dispatch('fetchAccount', accountId)
          }

          if (getters.accounts[accountId] && getters.getLimitNumber('maxBanks')) {
            const bankId = getters.accounts[accountId].bankId

            if (bankId && !getters.banks[bankId]) {
              await dispatch('fetchBank', bankId)
            }
          }
        }

        if (cardId && !getters.cards[cardId] && getters.getLimitNumber('maxCards')) {
          await dispatch('fetchCard', cardId)

          if (!getters.cards[cardId]) {
            await updateFirestoreDoc('transactions', transactionId, { cardId: firestoreDeleteField })
          }
        }

        if (tags) {
          for (const tagId of tags) {
            if (!getters.tags[tagId]) {
              await dispatch('fetchTag', tagId)
            }
          }
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchDataForTransaction', params: { accountId, cardId, tags, transactionId } })
      } finally {
        t.stop()
      }
    },
    async fetchTransactionsMinDate({ commit, dispatch, getters }) {
      if (getters.lessonStep) { return }
      if (!getters.canUseLimit('setTransactionsSettings', false)) { return }

      const t = tracePerformance('fetchTransactionsMinDate')
      t.start()

      try {
        const request = {
          maxLimit: 1,
          wheres: [['owner', '==', getUid()]],
          order: [['date', 'asc']]
        }

        const transactions = await getFirestoreDocs({
          collectionName: 'transactions',
          ...request
        })

        if (transactions) {
          for (const transaction of transactions) {
            if (transaction.data.date) {
              const utcDate = new Date(transaction.data.date.getUTCFullYear(), transaction.data.date.getUTCMonth(), transaction.data.date.getUTCDate(), transaction.data.date.getUTCHours(), transaction.data.date.getUTCMinutes(), transaction.data.date.getUTCSeconds())
              await commit('setTransactionsMinDate', utcDate)
            }
          }
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchTransactionsMinDate', params: {} })
      } finally {
        t.stop()
      }
    },
    async fetchTransactionsMaxDate({ commit, dispatch, getters }) {
      if (getters.lessonStep) { return }
      if (!getters.canUseLimit('setTransactionsSettings', false)) { return }

      const t = tracePerformance('fetchTransactionsMaxDate')
      t.start()

      try {
        const request = {
          maxLimit: 1,
          wheres: [['owner', '==', getUid()]],
          order: [['date', 'desc']]
        }

        const transactions = await getFirestoreDocs({
          collectionName: 'transactions',
          ...request
        })

        if (transactions) {
          for (const transaction of transactions) {
            if (transaction.data.date) {
              const utcDate = new Date(transaction.data.date.getUTCFullYear(), transaction.data.date.getUTCMonth(), transaction.data.date.getUTCDate(), transaction.data.date.getUTCHours(), transaction.data.date.getUTCMinutes(), transaction.data.date.getUTCSeconds())
              await commit('setTransactionsMaxDate', utcDate)
            }
          }
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchTransactionsMaxDate', params: {} })
      } finally {
        t.stop()
      }
    },
    addTransactionButtonClicked({ commit, getters }, { from }) {
      commit('setClickedTransactionId', null)

      if (!getters.getAvailableLimitNumber('transactionsReset', 'transactions', 'maxTransactions')) {
        commit('setAppShowField', { payWall: true })
        return
      }

      commit('setTransactionsShowField', { add: true })

      if (from) { logAnalyticsEvent('addTransactionClicked', { from }) }
    },
    copyTransactionClicked({ commit, getters }, { from }) {
      if (!getters.clickedTransactionId) {
        const { toastify } = useNotifications()
        toastify.error(localizeFilter('NoTransactionSelected'))
        return
      }

      commit('setTransactionsShowField', { add: true })

      if (from) { logAnalyticsEvent('copyTransactionClicked', { from: from }) }
    },
    async createTransactions({ commit, dispatch, getters }, transactions) {
      if (getters.lessonStep) { return }

      if (!transactions || !transactions.length) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('createTransactions')
      t.start()

      const syncTimestamp = new Date()

      try {
        commit('setProcessing', true)
        const batchData = []

        let data = []

        for (let i = 0; i < transactions.length; i++) {
          const transaction = transactions[i]

          if (!transaction || !transaction.accountId || !transaction.date || !transaction.sum) {
            commit('setError', localizeFilter('Error'))
            return false
          }

          const date = new Date(transaction.date)
          const utcDate = new Date()
          utcDate.setUTCFullYear(date.getFullYear())
          utcDate.setUTCMonth(date.getMonth())
          utcDate.setUTCDate(date.getDate())
          utcDate.setUTCHours(date.getHours())
          utcDate.setUTCMinutes(date.getMinutes())
          utcDate.setUTCSeconds(date.getSeconds())

          let account = getters.accounts[transaction.accountId]
          if (!account) {
            await dispatch('fetchAccount', transaction.accountId)
            account = getters.accounts[transaction.accountId]
          }

          data[i] = {
            transactionYear: transaction.date.getFullYear() + '',
            transactionMonth: (transaction.date.getMonth() + 1) + '',
            transactionData: {
              date: utcDate,
              sum: Math.round(+transaction.sum),
              accountId: transaction.accountId,
              timestamp: firebaseTimestamp.fromMillis(+syncTimestamp + i),
              owner: getUid()
            }
          }

          if (transaction.cardId && getters.getLimitNumber('maxCards')) {
            data[i].transactionData.cardId = transaction.cardId
          }

          if (transaction.comment && getters.canUseLimit('transactionComment', true)) {
            data[i].transactionData.comment = transaction.comment
          }

          if (transaction.transferId && getters.canUseLimit('transfers', false)) {
            data[i].transactionData.transferId = transaction.transferId
          }

          data[i].transactionId = createId('transactions')

          batchData.push({
            timestamp: syncTimestamp,
            type: 'update',
            place: 'accounts',
            id: transaction.accountId,
            data: {
              sum: firestoreIncrement(Math.round(+transaction.sum))
            },
            noLogs: true
          })

          if (transaction.tags && transaction.tags.length) {
            data[i].changeTags = []

            for (const tagIdx in transaction.tags) {
              if (data[i].changeTags.length >= getters.getLimitNumber('tagsInTransaction')) {
                continue
              }

              const tgId = transaction.tags[tagIdx]
              let tag = getters.tags[tgId]

              if (!tag) {
                await dispatch('fetchTag', tgId)
                tag = getters.tags[tgId]
              }

              if (!tag) {
                transaction.tags.splice(tagIdx, 1)
              } else {
                const tagsSumsYearCurrencyId = data[i].transactionYear + ':' + account.currencyCode.toUpperCase()

                if (tag.sums[tagsSumsYearCurrencyId]) {
                  batchData.push({
                    timestamp: syncTimestamp,
                    type: 'update',
                    place: 'tagsSums',
                    id: tag.sums[tagsSumsYearCurrencyId],
                    data: {
                      [data[i].transactionMonth + '']: firestoreIncrement(Math.round(+transaction.sum))
                    },
                    logDataInfo: {
                      month: data[i].transactionMonth + '',
                      difference: Math.round(+transaction.sum)
                    }
                  })
                } else {
                  const tagsSumsId = createId('tagsSums')

                  const tagsSumsData = createTagsSumsData(syncTimestamp, account.currencyCode, data[i].transactionMonth, data[i].transactionYear, tgId, Math.round(+transaction.sum))

                  batchData.push({
                    timestamp: syncTimestamp,
                    type: 'set',
                    place: 'tagsSums',
                    id: tagsSumsId,
                    data: tagsSumsData
                  })

                  tag.sums[tagsSumsYearCurrencyId] = tagsSumsId

                  batchData.push({
                    timestamp: syncTimestamp,
                    type: 'update',
                    place: 'tags',
                    id: tgId,
                    data: {
                      sums: { ...tag.sums }
                    }
                  })
                }

                data[i].changeTags.push({
                  tagId: tgId,
                  difference: Math.round(+transaction.sum),
                  year: data[i].transactionYear,
                  currencyCode: account.currencyCode,
                  month: data[i].transactionMonth
                })
              }
            }

            data[i].transactionData.tags = []
            for (const tag of data[i].changeTags) {
              if (tag.tagId && !data[i].transactionData.tags.includes(tag.tagId)) {
                data[i].transactionData.tags.push(tag.tagId)
              }
            }
          }
        }

        if (data.length === 2 && data[0].transactionData.transferId && data[1].transactionData.transferId && getters.canUseLimit('transfers', false)) {
          data[0].transactionData.transferId = data[1].transactionId
          data[1].transactionData.transferId = data[0].transactionId
        }

        for (const transactionFullInfo of data) {
          const transactionLogData = {
            info: transactionFullInfo.transactionData
          }

          if (transactionFullInfo.changeTags && transactionFullInfo.changeTags.length) {
            transactionLogData.changeTags = transactionFullInfo.changeTags
          }

          batchData.push({
            timestamp: syncTimestamp,
            type: 'set',
            place: 'transactions',
            id: transactionFullInfo.transactionId,
            data: transactionFullInfo.transactionData,
            logData: transactionLogData
          })
        }

        if (data.length) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'update',
            place: 'userStats',
            id: getUid(),
            data: {
              transactions: firestoreIncrement(data.length)
            },
            noLogs: true
          })
        }

        const batchArray = await createBatch(batchData)

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        for (let y = 0; y < data.length; y++) {
          await dispatch('transactionAdded', {
            transactionId: data[y].transactionId,
            data: data[y].transactionData,
            increaseTotalNumber: 1
          })
        }

        await commit('resetTransactions')

        return data[0].transactionId
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'createTransactions', params: { transactions } })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async transactionAdded({ commit, dispatch, getters }, { transactionId, data, increaseTotalNumber = 0 }) {
      if (!transactionId || !data) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      if (data.date) {
        data.date = new Date(data.date.getUTCFullYear(), data.date.getUTCMonth(), data.date.getUTCDate(), data.date.getUTCHours(), data.date.getUTCMinutes(), data.date.getUTCSeconds())
      }

      await dispatch('fetchDataForTransaction', {
        accountId: data.accountId,
        cardId: data.cardId,
        tags: data.tags,
        transactionId
      })

      await commit('addTransactionToStore', { transactionId, data })

      if (data.date) {
        if (getters.transactionsMinDate && +data.date < +getters.transactionsMinDate) {
          await commit('setTransactionsMinDate', data.date)
        }

        if (getters.transactionsMaxDate && +data.date > +getters.transactionsMaxDate) {
          await commit('setTransactionsMaxDate', data.date)
        }
      }

      if (increaseTotalNumber) {
        await commit('increaseTotalNumberOf', { field: 'transactions', number: increaseTotalNumber })

        if (data.accountId && data.sum) {
          const account = getters.accounts[data.accountId]

          if (account) {
            await commit('updateAccount', {
              accountId: data.accountId,
              data: {
                sum: Math.round((+account.sum) + (+data.sum))
              }
            })

            await commit('resetAccounts')
          }

          if (data.tags && Array.isArray(data.tags) && data.tags.length && account.currencyCode && data.date) {
            for (const tagId of data.tags) {
              if (getters.tags[tagId] && getters.tags[tagId].sums[data.date.getFullYear() + ':' + [account.currencyCode.toUpperCase()]] !== undefined) {
                await commit('updateTagSum', {
                  tagsSumsId: getters.tags[tagId].sums[data.date.getFullYear() + ':' + [account.currencyCode.toUpperCase()]],
                  month: (data.date.getMonth() + 1) + '',
                  difference: Math.round(+data.sum)
                })
              }

              await commit('setTagMinMaxYear', { tagId, year: data.date.getFullYear() })
            }

            await commit('resetTags')
          }
        }
      }

      if (data.tags) {
        for (const tagId of data.tags) {
          if (!getters.tags[tagId]) {
            await dispatch('renewTransactionDeleteTag', {
              transactionId,
              tagsToDelete: [tagId],
              noNotifications: true
            })

            await dispatch('removeNonExistentTagData', tagId)
          }
        }
      }
    },
    async renewTransactionComment({ commit, dispatch, getters }, { transactionId, comment }) {
      if (getters.lessonStep) { return }

      if (!transactionId) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      if (comment && !getters.canUseLimit('transactionComment', true)) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('renewTransactionComment')
      t.start()

      const syncTimestamp = new Date()

      let transaction = getters.transactions[transactionId]
      if (!transaction) {
        commit('setError', localizeFilter('TransactionNotFount'))
        return false
      }

      commit('setProcessing', true)

      try {
        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'update',
            place: 'transactions',
            id: transactionId,
            data: {
              comment: comment ? (comment + '') : firestoreDeleteField
            },
            logDataInfo: { comment }
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('transactionEdited', [{
          transactionId,
          transactionData: { comment }
        }])

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'renewTransactionComment', params: { transactionId, comment } })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async renewTransactionDate({ commit, dispatch, getters }, { transactionId, dateNum }) {
      if (getters.lessonStep) { return }

      if (!transactionId || !dateNum || !Number.isInteger(dateNum)) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('renewTransactionDate')
      t.start()

      const syncTimestamp = new Date()

      const transaction = getters.transactions[transactionId]
      if (!transaction) {
        commit('setError', localizeFilter('TransactionNotFount'))
        return false
      }

      try {
        let account = getters.accounts[transaction.accountId]
        if (!account) {
          await dispatch('fetchAccount', transaction.accountId)
          account = getters.accounts[transaction.accountId]
        }

        commit('setProcessing', true)

        const date = new Date(dateNum)
        const utcDate = new Date()
        utcDate.setUTCFullYear(date.getFullYear())
        utcDate.setUTCMonth(date.getMonth())
        utcDate.setUTCDate(date.getDate())
        utcDate.setUTCHours(date.getHours())
        utcDate.setUTCMinutes(date.getMinutes())
        utcDate.setUTCSeconds(date.getSeconds())

        const batchData = []

        const transactionData = {
          date: utcDate
        }

        const transactionLogData = {
          info: {
            date: utcDate
          }
        }

        const transactionYear = transaction.date.getFullYear() + ''
        const transactionMonth = (transaction.date.getMonth() + 1) + ''

        const newTransactionYear = new Date(+dateNum).getFullYear() + ''
        const newTransactionMonth = (new Date(+dateNum).getMonth() + 1) + ''

        if (transaction.tags && (transactionYear !== newTransactionYear || transactionMonth !== newTransactionMonth)) {
          transactionLogData.changeTags = []

          for (const tagId of transaction.tags) {
            if (getters.tags[tagId]) {
              // Decrement old tagsSums ==>

              transactionLogData.changeTags.push({
                tagId,
                difference: Math.round((+transaction.sum) * -1),
                year: transactionYear,
                currencyCode: account.currencyCode,
                month: transactionMonth
              })

              const tagsSumsYearCurrencyId = transactionYear + ':' + account.currencyCode.toUpperCase()

              if (getters.tags[tagId].sums[tagsSumsYearCurrencyId]) {
                const tagsSumsId = getters.tags[tagId].sums[tagsSumsYearCurrencyId]

                batchData.push({
                  timestamp: syncTimestamp,
                  type: 'update',
                  place: 'tagsSums',
                  id: tagsSumsId,
                  data: {
                    [transactionMonth + '']: firestoreIncrement(Math.round((+transaction.sum) * -1))
                  },
                  logDataInfo: {
                    month: transactionMonth + '',
                    difference: Math.round((+transaction.sum) * -1)
                  }
                })
              }
              // <== Decrement old tagsSums

              // Increment new tagsSums ==>

              transactionLogData.changeTags.push({
                tagId,
                difference: Math.round(+transaction.sum),
                year: newTransactionYear,
                currencyCode: account.currencyCode,
                month: newTransactionMonth
              })

              const newTagsSumsYearCurrencyId = newTransactionYear + ':' + account.currencyCode.toUpperCase()

              if (getters.tags[tagId].sums[newTagsSumsYearCurrencyId]) {
                const tagsSumsId = getters.tags[tagId].sums[newTagsSumsYearCurrencyId]

                batchData.push({
                  timestamp: syncTimestamp,
                  type: 'update',
                  place: 'tagsSums',
                  id: tagsSumsId,
                  data: {
                    [newTransactionMonth + '']: firestoreIncrement(Math.round(+transaction.sum))
                  },
                  logDataInfo: {
                    month: newTransactionMonth + '',
                    difference: Math.round(+transaction.sum)
                  }
                })
              } else {
                const tagsSumsId = createId('tagsSums')

                const tagsSumsData = createTagsSumsData(syncTimestamp, account.currencyCode, newTransactionMonth, newTransactionYear, tagId, Math.round(+transaction.sum))

                batchData.push({
                  timestamp: syncTimestamp,
                  type: 'set',
                  place: 'tagsSums',
                  id: tagsSumsId,
                  data: tagsSumsData
                })

                const tagsSumsIdsForTag = getters.tags[tagId].sums
                tagsSumsIdsForTag[newTagsSumsYearCurrencyId] = tagsSumsId

                batchData.push({
                  timestamp: syncTimestamp,
                  type: 'update',
                  place: 'tags',
                  id: tagId,
                  data: {
                    sums: { ...tagsSumsIdsForTag }
                  }
                })
              }

              // <== Increment new tagsSums
            }
          }
        }

        let transferTransactionLogData

        if (transaction.transferId) {
          transferTransactionLogData = {
            info: {}
          }

          if (!getters.transactions[transaction.transferId]) {
            await dispatch('fetchTransaction', transaction.transferId)
          }

          const transferTransastion = getters.transactions[transaction.transferId]

          if (getters.canUseLimit('transfers', false)) {
            transferTransactionLogData.info.date = utcDate

            if (!getters.accounts[transferTransastion.accountId]) {
              await dispatch('fetchAccount', transferTransastion.accountId)
            }
            const transferAccount = getters.accounts[transferTransastion.accountId]

            batchData.push({
              timestamp: syncTimestamp,
              type: 'update',
              place: 'transactions',
              id: transaction.transferId,
              data: {
                date: utcDate
              },
              noLogs: true
            })

            const transferTransastionMonth = transferTransastion.date.getMonth() + 1

            if (transferTransastion.tags && (transactionYear !== newTransactionYear || transactionMonth !== newTransactionMonth)) {
              transferTransactionLogData.changeTags = []

              for (const tagId of transferTransastion.tags) {
                if (getters.tags[tagId]) {
                  // Decrement old transfer tagsSums ==>

                  transferTransactionLogData.changeTags.push({
                    tagId,
                    difference: Math.round((+transferTransastion.sum) * -1),
                    year: transactionYear,
                    currencyCode: transferAccount.currencyCode,
                    month: transferTransastionMonth + ''
                  })

                  const tagsSumsYearCurrencyId = transactionYear + ':' + transferAccount.currencyCode.toUpperCase()

                  if (getters.tags[tagId].sums[tagsSumsYearCurrencyId]) {
                    const tagsSumsId = getters.tags[tagId].sums[tagsSumsYearCurrencyId]

                    batchData.push({
                      timestamp: syncTimestamp,
                      type: 'update',
                      place: 'tagsSums',
                      id: tagsSumsId,
                      data: {
                        [transferTransastionMonth + '']: firestoreIncrement(Math.round((+transferTransastion.sum) * -1))
                      },
                      logDataInfo: {
                        month: transferTransastionMonth + '',
                        difference: Math.round((+transferTransastion.sum) * -1)
                      }
                    })
                  }
                  // <== Decrement old transfer tagsSums

                  // Increment new transfer tagsSums ==>

                  transferTransactionLogData.changeTags.push({
                    tagId,
                    difference: Math.round(+transferTransastion.sum),
                    year: newTransactionYear,
                    currencyCode: transferAccount.currencyCode,
                    month: newTransactionMonth
                  })

                  const newTagsSumsYearCurrencyId = newTransactionYear + ':' + transferAccount.currencyCode.toUpperCase()

                  if (getters.tags[tagId].sums[newTagsSumsYearCurrencyId]) {
                    const tagsSumsId = getters.tags[tagId].sums[newTagsSumsYearCurrencyId]

                    batchData.push({
                      timestamp: syncTimestamp,
                      type: 'update',
                      place: 'tagsSums',
                      id: tagsSumsId,
                      data: {
                        [newTransactionMonth + '']: firestoreIncrement(Math.round(+transferTransastion.sum))
                      },
                      logDataInfo: {
                        month: newTransactionMonth + '',
                        difference: Math.round(+transferTransastion.sum)
                      }
                    })
                  } else {
                    const tagsSumsId = createId('tagsSums')

                    const tagsSumsData = createTagsSumsData(syncTimestamp, transferTransastion.currencyCode, newTransactionMonth, newTransactionYear, tagId, Math.round(+transferTransastion.sum))

                    batchData.push({
                      timestamp: syncTimestamp,
                      type: 'set',
                      place: 'tagsSums',
                      id: tagsSumsId,
                      data: tagsSumsData
                    })

                    const tagsSumsIdsForTag = getters.tags[tagId].sums
                    tagsSumsIdsForTag[newTagsSumsYearCurrencyId] = tagsSumsId

                    batchData.push({
                      timestamp: syncTimestamp,
                      type: 'update',
                      place: 'tags',
                      id: tagId,
                      data: {
                        sums: { ...tagsSumsIdsForTag }
                      }
                    })
                  }

                  // <== Increment new transfer tagsSums
                }
              }
            }
          } else {
            transactionData.transferId = firestoreDeleteField
            transactionLogData.info.transferId = null

            transferTransactionLogData.info.transferId = null

            batchData.push({
              timestamp: syncTimestamp,
              type: 'update',
              place: 'transactions',
              id: transaction.transferId,
              data: {
                transferId: firestoreDeleteField
              },
              noLogs: true
            })
          }
        }

        batchData.push({
          timestamp: syncTimestamp,
          type: 'update',
          place: 'transactions',
          id: transactionId,
          data: transactionData,
          logData: transactionLogData
        })

        if (transferTransactionLogData) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'set',
            place: 'logs',
            logPlace: 'transactions',
            logAction: 'edited',
            id: transaction.transferId,
            data: transferTransactionLogData
          })
        }

        const batchArray = await createBatch(batchData)

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        const editTransaction = {
          transactionId,
          transactionData: {
            date: utcDate
          }
        }

        if (transactionData.transferId !== undefined) {
          editTransaction.transactionData.transferId = null
        }

        if (transactionLogData.changeTags) {
          editTransaction.changeTags = transactionLogData.changeTags
        }

        const editTransactions = []
        editTransactions.push(editTransaction)

        if (transferTransactionLogData && transferTransactionLogData.info) {
          const editTransferTransaction = {
            transactionId: transaction.transferId,
            transactionData: {}
          }

          if (transferTransactionLogData.info.date) {
            editTransferTransaction.transactionData.date = utcDate
          }

          if (transferTransactionLogData.changeTags) {
            editTransferTransaction.changeTags = transferTransactionLogData.changeTags
          }

          if (transferTransactionLogData.info.transferId !== undefined) {
            editTransferTransaction.transactionData.transferId = transferTransactionLogData.info.transferId
          }

          editTransactions.push(editTransferTransaction)
        }

        await dispatch('transactionEdited', editTransactions)

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'renewTransactionDate', params: { transactionId, dateNum } })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async renewTransactionSum({ commit, dispatch, getters }, { transactionId, sum }) {
      if (getters.lessonStep) { return }

      if (!transactionId || !sum || !Number.isInteger(+sum)) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('renewTransactionSum')
      t.start()

      const syncTimestamp = new Date()

      const transaction = getters.transactions[transactionId]
      if (!transaction) {
        commit('setError', localizeFilter('TransactionNotFount'))
        return false
      }

      try {
        let account = getters.accounts[transaction.accountId]

        if (!account) {
          await dispatch('fetchAccount', transaction.accountId)
          account = getters.accounts[transaction.accountId]
        }

        commit('setProcessing', true)

        const difference = Math.round((+sum) - (+transaction.sum))

        const batchData = [
          {
            timestamp: syncTimestamp,
            type: 'update',
            place: 'accounts',
            id: transaction.accountId,
            data: { sum: firestoreIncrement(Math.round(difference)) },
            noLogs: true
          }
        ]

        const transactionData = {
          sum: firestoreIncrement(Math.round(difference))
        }

        const transactionLogData = {
          info: {
            sum: sum
          },
          changeAccounts: [{
            accountId: transaction.accountId,
            difference: Math.round(difference)
          }]
        }

        const transactionYear = transaction.date.getFullYear() + ''
        const transactionMonth = (transaction.date.getMonth() + 1) + ''

        if (transaction.tags) {
          transactionLogData.changeTags = []

          for (const tagId of transaction.tags) {
            if (getters.tags[tagId]) {
              transactionLogData.changeTags.push({
                tagId,
                difference: Math.round(difference),
                year: transactionYear,
                currencyCode: account.currencyCode,
                month: transactionMonth
              })

              const tagsSumsYearCurrencyId = transactionYear + ':' + account.currencyCode.toUpperCase()

              if (getters.tags[tagId].sums[tagsSumsYearCurrencyId]) {
                const tagsSumsId = getters.tags[tagId].sums[tagsSumsYearCurrencyId]

                batchData.push({
                  timestamp: syncTimestamp,
                  type: 'update',
                  place: 'tagsSums',
                  id: tagsSumsId,
                  data: {
                    [transactionMonth + '']: firestoreIncrement(Math.round(+difference))
                  },
                  logDataInfo: {
                    month: transactionMonth + '',
                    difference: Math.round(+difference)
                  }
                })
              }
            }
          }
        }

        let transferTransactionLogData

        if (transaction.transferId) {
          transferTransactionLogData = {
            info: {}
          }

          if (!getters.transactions[transaction.transferId]) {
            await dispatch('fetchTransaction', transaction.transferId)
          }

          const transferTransastion = getters.transactions[transaction.transferId]

          if (getters.canUseLimit('transfers', false)) {
            transferTransactionLogData.info.sum = transferTransastion.sum + Math.round(difference * -1)

            if (!getters.accounts[transferTransastion.accountId]) {
              await dispatch('fetchAccount', transferTransastion.accountId)
            }
            const transferAccount = getters.accounts[transferTransastion.accountId]

            batchData.push({
              timestamp: syncTimestamp,
              type: 'update',
              place: 'transactions',
              id: transaction.transferId,
              data: {
                sum: firestoreIncrement(Math.round(difference * -1))
              },
              noLogs: true
            })

            batchData.push({
              timestamp: syncTimestamp,
              type: 'update',
              place: 'accounts',
              id: transferTransastion.accountId,
              data: {
                sum: firestoreIncrement(Math.round(difference * -1))
              },
              noLogs: true
            })

            transferTransactionLogData.changeAccounts = [{
              accountId: transferTransastion.accountId,
              difference: Math.round(difference * -1)
            }]

            const transferTransastionMonth = transferTransastion.date.getMonth() + 1

            if (transferTransastion.tags) {
              transferTransactionLogData.changeTags = []

              for (const tagId of transferTransastion.tags) {
                if (getters.tags[tagId]) {
                  transferTransactionLogData.changeTags.push({
                    tagId,
                    difference: Math.round(difference * -1),
                    year: transactionYear,
                    currencyCode: transferAccount.currencyCode,
                    month: transferTransastionMonth + ''
                  })

                  const tagsSumsYearCurrencyId = transactionYear + ':' + transferAccount.currencyCode.toUpperCase()

                  if (getters.tags[tagId].sums[tagsSumsYearCurrencyId]) {
                    const tagsSumsId = getters.tags[tagId].sums[tagsSumsYearCurrencyId]

                    batchData.push({
                      timestamp: syncTimestamp,
                      type: 'update',
                      place: 'tagsSums',
                      id: tagsSumsId,
                      data: {
                        [transferTransastionMonth + '']: firestoreIncrement(Math.round(+difference * -1))
                      },
                      logDataInfo: {
                        month: transferTransastionMonth + '',
                        difference: Math.round(+difference * -1)
                      }
                    })
                  }
                }
              }
            }
          } else {
            transactionData.transferId = firestoreDeleteField
            transactionLogData.info.transferId = null

            transferTransactionLogData.info.transferId = null

            batchData.push({
              timestamp: syncTimestamp,
              type: 'update',
              place: 'transactions',
              id: transaction.transferId,
              data: {
                transferId: firestoreDeleteField
              },
              noLogs: true
            })
          }
        }

        batchData.push({
          timestamp: syncTimestamp,
          type: 'update',
          place: 'transactions',
          id: transactionId,
          data: transactionData,
          logData: transactionLogData
        })

        if (transferTransactionLogData) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'set',
            place: 'logs',
            logPlace: 'transactions',
            logAction: 'edited',
            id: transaction.transferId,
            data: transferTransactionLogData
          })
        }

        const batchArray = await createBatch(batchData)

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        const editTransaction = {
          transactionId,
          transactionData: {
            sum: +sum
          }
        }

        if (transactionData.transferId !== undefined) {
          editTransaction.transactionData.transferId = null
        }

        if (transactionLogData.changeTags) {
          editTransaction.changeTags = transactionLogData.changeTags
        }

        if (transactionLogData.changeAccounts) {
          editTransaction.changeAccounts = transactionLogData.changeAccounts
        }

        const editTransactions = []
        editTransactions.push(editTransaction)

        if (transferTransactionLogData && transferTransactionLogData.info) {
          const editTransferTransaction = {
            transactionId: transaction.transferId,
            transactionData: {}
          }

          if (transferTransactionLogData.info.sum) {
            editTransferTransaction.transactionData.sum = transferTransactionLogData.info.sum
          }

          if (transferTransactionLogData.changeTags) {
            editTransferTransaction.changeTags = transferTransactionLogData.changeTags
          }

          if (transferTransactionLogData.changeAccounts) {
            editTransferTransaction.changeAccounts = transferTransactionLogData.changeAccounts
          }

          if (transferTransactionLogData.info.transferId !== undefined) {
            editTransferTransaction.transactionData.transferId = transferTransactionLogData.info.transferId
          }

          editTransactions.push(editTransferTransaction)
        }

        await dispatch('transactionEdited', editTransactions)

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'renewTransactionSum', params: { transactionId, sum } })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async renewTransactionAccount({ commit, dispatch, getters }, { transactionId, accountId, cardId = null }) {
      if (getters.lessonStep) { return }

      if (!transactionId || !accountId) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('renewTransactionAccount')
      t.start()

      const syncTimestamp = new Date()

      try {
        const transaction = getters.transactions[transactionId]
        if (!transaction) {
          commit('setError', localizeFilter('TransactionNotFount'))
          return false
        }

        let account = getters.accounts[transaction.accountId]
        if (!account) {
          await dispatch('fetchAccount', transaction.accountId)
          account = getters.accounts[transaction.accountId]
        }

        if (accountId === transaction.accountId && cardId === transaction.cardId) {
          commit('setError', localizeFilter('ThisAccountAlreadySelectedForTransaction'))
          return false
        }

        commit('setProcessing', true)

        const batchData = []

        const transactionData = {
          accountId
        }

        const transactionLogData = {
          info: {
            accountId,
          },
          changeAccounts: []
        }

        if (!cardId) {
          if (transaction.cardId) {
            transactionData.cardId = firestoreDeleteField
            transactionLogData.info.cardId = null
          }
        } else if (getters.getLimitNumber('maxCards')) {
          transactionData.cardId = cardId
          transactionLogData.info.cardId = cardId
        }

        batchData.push({
          timestamp: syncTimestamp,
          type: 'update',
          place: 'accounts',
          id: transaction.accountId,
          data: {
            sum: firestoreIncrement(Math.round((+transaction.sum) * -1))
          },
          noLogs: true
        })

        transactionLogData.changeAccounts.push({
          accountId: transaction.accountId,
          difference: Math.round((+transaction.sum) * -1)
        })

        if (!getters.accounts[accountId]) {
          await dispatch('fetchAccount', accountId)
        }

        const newAccount = getters.accounts[accountId]

        batchData.push({
          timestamp: syncTimestamp,
          type: 'update',
          place: 'accounts',
          id: accountId,
          data: {
            sum: firestoreIncrement(Math.round(+transaction.sum))
          },
          noLogs: true
        })

        transactionLogData.changeAccounts.push({
          accountId,
          difference: Math.round(+transaction.sum)
        })

        const transactionYear = transaction.date.getFullYear() + ''
        const transactionMonth = (transaction.date.getMonth() + 1) + ''

        let transferTransactionLogData
        if (transaction.transferId) {
          transferTransactionLogData = {
            info: {}
          }

          if (!getters.transactions[transaction.transferId]) {
            await dispatch('fetchTransaction', transaction.transferId)
          }

          const transferTransastion = getters.transactions[transaction.transferId]

          if (getters.canUseLimit('transfers', false) && account.currencyCode !== newAccount.currencyCode) {
            commit('setError', localizeFilter('UnableChangeCurrencyForTransfers'))
            return false
          } else if (accountId === transaction.accountId && cardId == transaction.cardId) {
            commit('setError', localizeFilter('ThisAccountAlreadySelectedForTransaction'))
            return false
          } else if (getters.canUseLimit('transfers', false)
            && (
              (accountId === transferTransastion.accountId)
            )
            && (cardId == undefined || (cardId === transferTransastion.cardId))
          ) {
            commit('setError', localizeFilter('AccountUsedInCorrespondingTransaction'))
            return false
          } else if (accountId === transferTransastion.accountId) {
            transactionData.transferId = firestoreDeleteField
            transactionLogData.info.transferId = null

            transferTransactionLogData.info.transferId = null

            batchData.push({
              timestamp: syncTimestamp,
              type: 'update',
              place: 'transactions',
              id: transaction.transferId,
              data: {
                transferId: firestoreDeleteField
              },
              noLogs: true
            })
          }
        }

        if (account.currencyCode !== newAccount.currencyCode) {
          if (transaction.tags) {
            transactionLogData.changeTags = []

            for (const tagId of transaction.tags) {
              if (getters.tags[tagId]) {
                // Decrement old tagsSums ==>

                transactionLogData.changeTags.push({
                  tagId,
                  difference: Math.round((+transaction.sum) * -1),
                  year: transactionYear,
                  currencyCode: account.currencyCode,
                  month: transactionMonth
                })

                const tagsSumsYearCurrencyId = transactionYear + ':' + account.currencyCode.toUpperCase()
                if (getters.tags[tagId].sums[tagsSumsYearCurrencyId]) {
                  const tagsSumsId = getters.tags[tagId].sums[tagsSumsYearCurrencyId]

                  batchData.push({
                    timestamp: syncTimestamp,
                    type: 'update',
                    place: 'tagsSums',
                    id: tagsSumsId,
                    data: {
                      [transactionMonth + '']: firestoreIncrement(Math.round((+transaction.sum) * -1))
                    },
                    logDataInfo: {
                      month: transactionMonth + '',
                      difference: Math.round((+transaction.sum) * -1)
                    }
                  })
                }
                // <== Decrement old tagsSums

                // Increment new tagsSums ==>

                transactionLogData.changeTags.push({
                  tagId,
                  difference: Math.round(+transaction.sum),
                  year: transactionYear,
                  currencyCode: newAccount.currencyCode,
                  month: transactionMonth
                })

                const newTagsSumsYearCurrencyId = transactionYear + ':' + newAccount.currencyCode.toUpperCase()

                if (getters.tags[tagId].sums[newTagsSumsYearCurrencyId]) {
                  const tagsSumsId = getters.tags[tagId].sums[newTagsSumsYearCurrencyId]

                  batchData.push({
                    timestamp: syncTimestamp,
                    type: 'update',
                    place: 'tagsSums',
                    id: tagsSumsId,
                    data: {
                      [transactionMonth + '']: firestoreIncrement(Math.round(+transaction.sum))
                    },
                    logDataInfo: {
                      month: transactionMonth + '',
                      difference: Math.round(+transaction.sum)
                    }
                  })
                } else {
                  const tagsSumsId = createId('tagsSums')

                  const tagsSumsData = createTagsSumsData(syncTimestamp, newAccount.currencyCode, transactionMonth, transactionYear, tagId, Math.round(+transaction.sum))

                  batchData.push({
                    timestamp: syncTimestamp,
                    type: 'set',
                    place: 'tagsSums',
                    id: tagsSumsId,
                    data: tagsSumsData,
                    logDataInfo: {
                      month: transactionMonth + '',
                      difference: Math.round(+transaction.sum)
                    }
                  })

                  const tagsSumsIdsForTag = getters.tags[tagId].sums
                  tagsSumsIdsForTag[newTagsSumsYearCurrencyId] = tagsSumsId

                  batchData.push({
                    timestamp: syncTimestamp,
                    type: 'set',
                    place: 'tags',
                    id: tagId,
                    data: {
                      sums: { ...tagsSumsIdsForTag }
                    }
                  })
                }
                // <== Increment new tagsSums
              }
            }
          }
        }

        batchData.push({
          timestamp: syncTimestamp,
          type: 'update',
          place: 'transactions',
          id: transactionId,
          data: transactionData,
          logData: transactionLogData
        })

        if (transferTransactionLogData) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'set',
            place: 'logs',
            logPlace: 'transactions',
            logAction: 'edited',
            id: transaction.transferId,
            data: transferTransactionLogData
          })
        }

        const batchArray = await createBatch(batchData)

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        const editTransaction = {
          transactionId,
          transactionData: {
            accountId,
            cardId: null
          }
        }

        if (cardId && getters.getLimitNumber('maxCards')) {
          editTransaction.transactionData.cardId = cardId
        }

        if (transactionData.transferId !== undefined) {
          editTransaction.transactionData.transferId = null
        }

        if (transactionLogData.changeTags) {
          editTransaction.changeTags = transactionLogData.changeTags
        }

        if (transactionLogData.changeAccounts) {
          editTransaction.changeAccounts = transactionLogData.changeAccounts
        }

        const editTransactions = [editTransaction]

        if (transferTransactionLogData && transferTransactionLogData.info) {
          const editTransferTransaction = {
            transactionId: transaction.transferId,
            transactionData: {}
          }

          if (transferTransactionLogData.info.transferId !== undefined) {
            editTransferTransaction.transactionData.transferId = transferTransactionLogData.info.transferId
          }

          editTransactions.push(editTransferTransaction)
        }

        await dispatch('transactionEdited', editTransactions)

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'renewTransactionAccount', params: { transactionId, accountId, cardId } })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async renewTransactionAddTags({ commit, dispatch, getters }, { transactionId, tagsToAdd }) {
      if (getters.lessonStep) { return }

      if (!transactionId || !tagsToAdd) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      if (!Array.isArray(tagsToAdd) || !tagsToAdd.length) {
        commit('setError', localizeFilter('NoTagsToAdd'))
        return false
      }

      const t = tracePerformance('renewTransactionAddTags')
      t.start()

      const syncTimestamp = new Date()

      try {
        const transaction = getters.transactions[transactionId]
        if (!transaction) {
          commit('setError', localizeFilter('TransactionNotFount'))
          return false
        }

        if (transaction.tags && transaction.tags.length >= getters.getLimitNumber('tagsInTransaction')) {
          commit('setAppShowField', { payWall: true })
          return false
        }

        let account = getters.accounts[transaction.accountId]
        if (!account) {
          await dispatch('fetchAccount', transaction.accountId)
          account = getters.accounts[transaction.accountId]
        }

        commit('setProcessing', true)

        if (!transaction.tags) {
          await updateFirestoreDoc('transactions', transactionId, { tags: [] })
          transaction.tags = []
        }

        const transactionLogData = {
          info: {},
          addTags: [],
          changeTags: []
        }

        const batchData = []

        const transactionYear = transaction.date.getFullYear() + ''
        const transactionMonth = (transaction.date.getMonth() + 1) + ''

        for (const tagId of tagsToAdd) {
          if (transaction.tags >= getters.getLimitNumber('tagsInTransaction')) {
            break
          }

          let tag = getters.tags[tagId]
          if (!tag) {
            await dispatch('fetchTag', tagId)
            tag = getters.tags[tagId]
          }

          if (!tag) {
            commit('setError', localizeFilter('CantAddDeletedTag'))
            return false
          }

          if (!transaction.tags.includes(tagId) && getters.tags[tagId]) {
            const newTagsSumsYearCurrencyId = transactionYear + ':' + account.currencyCode.toUpperCase()

            if (getters.tags[tagId].sums[newTagsSumsYearCurrencyId]) {
              const tagsSumsId = getters.tags[tagId].sums[newTagsSumsYearCurrencyId]

              batchData.push({
                timestamp: syncTimestamp,
                type: 'update',
                place: 'tagsSums',
                id: tagsSumsId,
                data: {
                  [transactionMonth + '']: firestoreIncrement(Math.round(+transaction.sum))
                },
                logDataInfo: {
                  month: transactionMonth + '',
                  difference: Math.round(+transaction.sum)
                }
              })
            } else {
              const tagsSumsId = createId('tagsSums')

              const tagsSumsData = createTagsSumsData(syncTimestamp, account.currencyCode, transactionMonth, transactionYear, tagId, Math.round(+transaction.sum))

              batchData.push({
                timestamp: syncTimestamp,
                type: 'set',
                place: 'tagsSums',
                id: tagsSumsId,
                data: tagsSumsData
              })

              const tagsSumsIdsForTag = getters.tags[tagId].sums
              tagsSumsIdsForTag[newTagsSumsYearCurrencyId] = tagsSumsId

              batchData.push({
                timestamp: syncTimestamp,
                type: 'update',
                place: 'tags',
                id: tagId,
                data: {
                  sums: { ...tagsSumsIdsForTag }
                }
              })
            }

            transactionLogData.changeTags.push({
              tagId,
              difference: Math.round(+transaction.sum),
              year: transactionYear,
              currencyCode: account.currencyCode,
              month: transactionMonth
            })

            transaction.tags.push(tagId)
            transactionLogData.addTags.push(tagId)
          }
        }

        if (transactionLogData.addTags.length) {
          transactionLogData.info.tags = transaction.tags

          batchData.push({
            timestamp: syncTimestamp,
            type: 'update',
            place: 'transactions',
            id: transactionId,
            data: { tags: transaction.tags },
            logData: transactionLogData
          })
        }

        const batchArray = await createBatch(batchData)

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        const editTransaction = {
          transactionId,
          transactionData: {
            tags: transaction.tags
          }
        }

        if (transactionLogData.changeTags) {
          editTransaction.changeTags = transactionLogData.changeTags
        }

        const editTransactions = [editTransaction]

        await dispatch('transactionEdited', editTransactions)

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'renewTransactionAddTags', params: { transactionId, tagsToAdd } })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async renewTransactionDeleteTag({ commit, dispatch, getters }, { transactionId, tagsToDelete, noNotifications }) {
      if (getters.lessonStep) { return }

      if (!transactionId || !tagsToDelete) {
        if (!noNotifications) { commit('setError', localizeFilter('Error')) }
        return false
      }

      const transaction = getters.transactions[transactionId]
      if (!transaction) {
        if (!noNotifications) { commit('setError', localizeFilter('TransactionNotFount')) }
        return false
      }

      if (!Array.isArray(tagsToDelete) || !tagsToDelete.length || !transaction.tags || !transaction.tags.length) {
        if (!noNotifications) { commit('setError', localizeFilter('NoTagsToDelete')) }
        return false
      }

      const t = tracePerformance('renewTransactionDeleteTag')
      t.start()

      const syncTimestamp = new Date()

      try {
        let account = getters.accounts[transaction.accountId]
        if (!account) {
          await dispatch('fetchAccount', transaction.accountId)
          account = getters.accounts[transaction.accountId]
        }

        commit('setProcessing', true)

        const batchData = []

        const transactionLogData = {
          info: {},
          deleteTags: [],
          changeTags: []
        }

        const transactionYear = transaction.date.getFullYear() + ''
        const transactionMonth = (transaction.date.getMonth() + 1) + ''

        for (const tagId of tagsToDelete) {
          if (transaction.tags.includes(tagId)) {
            const tagsSumsYearCurrencyId = transactionYear + ':' + account.currencyCode.toUpperCase()

            if (getters.tags[tagId] && getters.tags[tagId].sums && getters.tags[tagId].sums[tagsSumsYearCurrencyId]) {
              const tagsSumsId = getters.tags[tagId].sums[tagsSumsYearCurrencyId]

              batchData.push({
                timestamp: syncTimestamp,
                type: 'update',
                place: 'tagsSums',
                id: tagsSumsId,
                data: {
                  [transactionMonth + '']: firestoreIncrement(Math.round((+transaction.sum) * -1))
                },
                logDataInfo: {
                  month: transactionMonth + '',
                  difference: Math.round((+transaction.sum) * -1)
                }
              })

              transactionLogData.changeTags.push({
                tagId,
                difference: Math.round((+transaction.sum) * -1),
                year: transactionYear,
                currencyCode: account.currencyCode,
                month: transactionMonth
              })
            }

            transaction.tags.splice(transaction.tags.indexOf(tagId), 1)
            transactionLogData.deleteTags.push(tagId)
          } else {
            if (!noNotifications) { commit('setError', localizeFilter('CantDeleteSelectedTag')) }
            return false
          }
        }

        transactionLogData.info.tags = [...transaction.tags]
        batchData.push({
          timestamp: syncTimestamp,
          type: 'update',
          place: 'transactions',
          id: transactionId,
          data: {
            tags: transaction.tags.length ? transaction.tags : firestoreDeleteField
          },
          logData: transactionLogData
        })

        const batchArray = await createBatch(batchData)

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        const editTransaction = {
          transactionId,
          transactionData: {
            tags: transaction.tags
          }
        }

        if (transactionLogData.changeTags) {
          editTransaction.changeTags = transactionLogData.changeTags
        }

        const editTransactions = [editTransaction]

        await dispatch('transactionEdited', editTransactions)

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'renewTransactionDeleteTag', params: { transactionId, tagsToDelete } })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async transactionEdited({ commit, dispatch, getters }, transactionsArr) {
      if (getters.lessonStep) { return }

      if (!transactionsArr || !Array.isArray(transactionsArr)) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      for (const changedTransaction of transactionsArr) {
        const transactionId = changedTransaction.transactionId
        const transaction = getters.transactions[transactionId]

        const transactionData = changedTransaction.transactionData

        if (transactionData) {
          if (transactionData.date) {
            transaction.date = new Date(transaction.date.getUTCFullYear(), transaction.date.getUTCMonth(), transaction.date.getUTCDate(), transaction.date.getUTCHours(), transaction.date.getUTCMinutes(), transaction.date.getUTCSeconds())
          }

          const newTransactionData = {}

          if (transactionData.comment !== undefined) {
            if (transactionData.comment) {
              newTransactionData.comment = transactionData.comment
            } else {
              newTransactionData.comment = ''
            }
          }

          if (transactionData.date) {
            newTransactionData.date = transactionData.date
          }

          if (transactionData.sum) {
            newTransactionData.sum = +transactionData.sum
          }

          if (transactionData.accountId !== undefined) {
            newTransactionData.accountId = transactionData.accountId
          }

          if (transactionData.cardId !== undefined) {
            newTransactionData.cardId = transactionData.cardId
          }

          if (transactionData.transferId !== undefined) {
            newTransactionData.transferId = transactionData.transferId
          }

          if (changedTransaction.addTags || changedTransaction.deleteTags) {
            newTransactionData.tags = []

            if (transaction && transaction.tags) {
              newTransactionData.tags = transaction.tags.slice()
            }

            if (changedTransaction.addTags) {
              for (const tagId of changedTransaction.addTags) {
                if (!newTransactionData.tags.includes(tagId)) { newTransactionData.tags.push(tagId) }
              }
            }

            if (changedTransaction.deleteTags) {
              for (const tagId of changedTransaction.deleteTags) {
                if (newTransactionData.tags.includes(tagId)) {
                  newTransactionData.tags.splice(newTransactionData.tags.indexOf(tagId), 1)
                }
              }
            }
          }

          if (transactionData.receiptId !== undefined) {
            newTransactionData.receiptId = transactionData.receiptId
          }

          if (transactionData.receiptPic !== undefined) {
            newTransactionData.receiptPic = transactionData.receiptPic
          }

          if (transaction) {
            await dispatch('fetchDataForTransaction', {
              accountId: newTransactionData.accountId,
              cardId: newTransactionData.cardId,
              tags: newTransactionData.tags,
              transactionId
            })

            await commit('updateTransactionInStore', { transactionId, data: newTransactionData })
          }

          if (transactionData.date) {
            if (getters.transactionsMinDate && +transactionData.date < +getters.transactionsMinDate) {
              await commit('setTransactionsMinDate', transactionData.date)
            }

            if (getters.transactionsMaxDate && +transactionData.date > +getters.transactionsMaxDate) {
              await commit('setTransactionsMaxDate', transactionData.date)
            }
          }
        }

        if (changedTransaction.changeTags) {
          for (const tagInfo of changedTransaction.changeTags) {
            if (tagInfo.tagId && getters.tags[tagInfo.tagId] && getters.tags[tagInfo.tagId].sums[tagInfo.year + ':' + tagInfo.currencyCode.toUpperCase()] !== undefined) {
              await commit('updateTagSum', {
                tagsSumsId: getters.tags[tagInfo.tagId].sums[tagInfo.year + ':' + tagInfo.currencyCode.toUpperCase()],
                month: tagInfo.month + '',
                difference: tagInfo.difference
              })

              await commit('setTagMinMaxYear', { tagId: tagInfo.tagId, year: +tagInfo.year })
            }
          }

          commit('resetTags')
        }

        if (changedTransaction.changeAccounts) {
          for (const accountInfo of changedTransaction.changeAccounts) {
            if (accountInfo.accountId && getters.accounts[accountInfo.accountId])
              await commit('updateAccount', {
                accountId: accountInfo.accountId,
                data: {
                  sum: Math.round((+getters.accounts[accountInfo.accountId].sum) + (+accountInfo.difference))
                }
              })
          }

          commit('resetAccounts')
        }
      }

      commit('resetTransactions')
    },
    deleteTransactionClicked({ commit }) {
      commit('setTransactionsShowField', { delete: true })
    },
    async deleteTransaction({ commit, dispatch, getters }, { transactionId, deleteTransfer }) {
      if (getters.lessonStep || !getters.online) { return }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('WaitPreviousTask'), {
          icon: 'hourglass_empty'
        })
        return
      }

      if (!transactionId || !getters.transactions[transactionId]) {
        commit('setError', localizeFilter('Error'))
        return
      }

      const transaction = getters.transactions[transactionId]

      const t = tracePerformance('deleteTransaction')
      t.start()

      const syncTimestamp = new Date()

      let toastId

      try {
        if (!getters.accounts[transaction.accountId]) {
          await dispatch('fetchAccount', transaction.accountId)
        }
        const account = getters.accounts[transaction.accountId]

        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Deleting') + '...', { timeout: null })

        const batchData = [
          {
            timestamp: syncTimestamp,
            type: 'delete',
            place: 'transactions',
            id: transactionId,
            noLogs: true
          }
        ]

        batchData.push({
          timestamp: syncTimestamp,
          type: 'delete',
          place: 'transactions',
          id: transactionId,
          noLogs: true
        })

        let deleteLength = 1

        const logDataTransfer = {}
        if (transaction.transferId) {
          if (deleteTransfer) {
            deleteLength = deleteLength + 1

            batchData.push({
              timestamp: syncTimestamp,
              type: 'delete',
              place: 'transactions',
              id: transaction.transferId,
              noLogs: true
            })

            if (!getters.transactions[transaction.transferId]) {
              await dispatch('fetchTransaction', transaction.transferId)
            }
            const transferTransaction = getters.transactions[transaction.transferId]

            if (!getters.accounts[transferTransaction.accountId]) {
              await dispatch('fetchAccount', transferTransaction.accountId)
            }
            const transferAccount = getters.accounts[transferTransaction.accountId]

            if (transferAccount) {
              batchData.push({
                timestamp: syncTimestamp,
                type: 'update',
                place: 'accounts',
                id: transferTransaction.accountId,
                data: {
                  sum: firestoreIncrement(Math.round(+transferTransaction.sum * -1))
                },
                noLogs: true
              })

              logDataTransfer.changeAccounts = []
              logDataTransfer.changeAccounts.push({
                accountId: transferTransaction.accountId,
                difference: Math.round(+transferTransaction.sum * -1)
              })
            }

            if (transferTransaction.tags) {
              logDataTransfer.changeTags = []

              const tansferTransactionYear = transferTransaction.date.getFullYear() + ''
              const tansferTransactionMonth = (transferTransaction.date.getMonth() + 1) + ''

              for (const tagId of transferTransaction.tags) {
                const tag = getters.tags[tagId]
                if (tag) {
                  const tagsSumsYearCurrencyId = tansferTransactionYear + ':' + transferAccount.currencyCode.toUpperCase()

                  if (getters.tags[tagId].sums[tagsSumsYearCurrencyId]) {
                    const tagsSumsId = getters.tags[tagId].sums[tagsSumsYearCurrencyId]

                    batchData.push({
                      timestamp: syncTimestamp,
                      type: 'update',
                      place: 'tagsSums',
                      id: tagsSumsId,
                      data: {
                        [tansferTransactionMonth + '']: firestoreIncrement(Math.round((+transferTransaction.sum) * -1))
                      },
                      logDataInfo: {
                        month: tansferTransactionMonth + '',
                        difference: Math.round((+transferTransaction.sum) * -1)
                      }
                    })
                  }
                }

                logDataTransfer.changeTags.push({
                  tagId,
                  difference: Math.round(+transferTransaction.sum * -1),
                  year: tansferTransactionYear,
                  currencyCode: transferAccount.currencyCode,
                  month: tansferTransactionMonth
                })
              }
            }
          } else {
            batchData.push({
              timestamp: syncTimestamp,
              type: 'update',
              place: 'transactions',
              id: transaction.transferId,
              data: {
                transferId: firestoreDeleteField
              },
              noLogs: true
            })

            logDataTransfer.info = {}
            logDataTransfer.info.transferId = null
          }

          batchData.push({
            timestamp: syncTimestamp,
            type: 'set',
            place: 'logs',
            logPlace: 'transactions',
            logAction: deleteTransfer ? 'deleted' : 'edited',
            id: transaction.transferId,
            data: logDataTransfer
          })
        }

        const logData = {}

        batchData.push({
          timestamp: syncTimestamp,
          type: 'update',
          place: 'userStats',
          id: getUid(),
          data: {
            transactions: firestoreIncrement(deleteLength * -1)
          },
          noLogs: true
        })

        if (account) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'update',
            place: 'accounts',
            id: transaction.accountId,
            data: {
              sum: firestoreIncrement(Math.round(+transaction.sum * -1))
            },
            noLogs: true
          })

          logData.changeAccounts = []

          logData.changeAccounts.push({
            accountId: transaction.accountId,
            difference: Math.round(+transaction.sum * -1)
          })
        }

        if (transaction.tags) {
          logData.changeTags = []

          const transactionYear = transaction.date.getFullYear() + ''
          const transactionMonth = (transaction.date.getMonth() + 1) + ''

          for (const tagId of transaction.tags) {
            const tag = getters.tags[tagId]
            if (tag) {
              const tagsSumsYearCurrencyId = transactionYear + ':' + account.currencyCode.toUpperCase()

              if (getters.tags[tagId].sums[tagsSumsYearCurrencyId]) {
                const tagsSumsId = getters.tags[tagId].sums[tagsSumsYearCurrencyId]

                batchData.push({
                  timestamp: syncTimestamp,
                  type: 'update',
                  place: 'tagsSums',
                  id: tagsSumsId,
                  data: {
                    [transactionMonth + '']: firestoreIncrement(Math.round((+transaction.sum) * -1))
                  },
                  logDataInfo: {
                    month: transactionMonth + '',
                    difference: Math.round((+transaction.sum) * -1)
                  }
                })
              }
            }

            logData.changeTags.push({
              tagId,
              difference: Math.round(+transaction.sum * -1),
              year: transactionYear,
              currencyCode: account.currencyCode,
              month: transactionMonth
            })
          }
        }

        batchData.push({
          timestamp: syncTimestamp,
          type: 'set',
          place: 'logs',
          logPlace: 'transactions',
          logAction: 'deleted',
          id: transactionId,
          data: logData
        })

        const batchArray = await createBatch(batchData)

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        commit('setClickedTransactionId', null)

        await dispatch('transactionDeleted', { transactionId, changeAccounts: logData.changeAccounts, changeTags: logData.changeTags })

        if (transaction.transferId) {
          if (deleteTransfer) {
            await dispatch('transactionDeleted', { transactionId: transaction.transferId, changeAccounts: logDataTransfer.changeAccounts, changeTags: logDataTransfer.changeTags })

            await deleteImageFromStorage('receipts', transaction.transferId)
          } else {
            await dispatch('transactionEdited', [{
              transactionId: transaction.transferId,
              transactionData: {
                transferId: null
              }
            }])
          }
        }

        if (transaction.receiptId) { await deleteImageFromStorage('receipts', transaction.receiptId) }

        toastify.replace(toastId, localizeFilter('Deleted'), 'success')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'deleteTransaction', params: { transactionId, deleteTransfer }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async transactionDeleted({ commit, getters }, { transactionId = null, changeAccounts = null, changeTags = null }) {
      if (transactionId) {
        await commit('setDeletingTransactions', transactionId)

        setTimeout(async () => {
          await commit('deleteTransactionFromStore', transactionId)
        }, 500)
      }

      await commit('increaseTotalNumberOf', { field: 'transactions', number: -1 })

      if (changeAccounts) {
        for (const accountInfo of changeAccounts) {
          if (accountInfo.accountId && getters.accounts[accountInfo.accountId])
            await commit('updateAccount', {
              accountId: accountInfo.accountId,
              data: {
                sum: Math.round((+getters.accounts[accountInfo.accountId].sum) + (+accountInfo.difference))
              }
            })
        }

        commit('resetAccounts')
      }

      if (changeTags) {
        for (const tagInfo of changeTags) {
          if (tagInfo.tagId && getters.tags[tagInfo.tagId] && getters.tags[tagInfo.tagId].sums[tagInfo.year + ':' + tagInfo.currencyCode.toUpperCase()] !== undefined) {
            await commit('updateTagSum', {
              tagsSumsId: getters.tags[tagInfo.tagId].sums[tagInfo.year + ':' + tagInfo.currencyCode.toUpperCase()],
              month: tagInfo.month + '',
              difference: tagInfo.difference
            })
          }
        }

        commit('resetTags')
      }

      commit('resetTransactions')
    },
    showFilterTransactionsButtonClicked({ commit }, { section, value }) {
      commit('setTransactionsFiltersSection', section)
      commit('setTransactionsShowField', { filters: value })
    },
    clearTransactionsFilters({ commit }) {
      commit('setClearTransactionsFilters')
    },
    showTransactionsSettingsClicked({ commit, getters }) {
      if (!getters.canUseLimit('setTransactionsSettings', false)) {
        commit('setAppShowField', { payWall: true })
        return
      }

      commit('setTransactionsShowField', { transactionsSettings: true })
      logAnalyticsEvent('showTransactionsSettingsClicked', { from: 'left-menu' })
    }
  },
  getters: {
    transactions: s => s.transactions,
    clickedTransactionId: s => s.clickedTransactionId,
    transactionsReset: s => s.transactionsReset,
    filteredTransactions: (s, getters) => {
      if (getters.transactionsReset) {
        //
      }

      let transactionIds = Object.keys(s.transactions)

      if (transactionIds.length) {
        if (getters.transactionsSettingsFiltered) {
          const containaAnyTag = (transactionId) => {
            for (const tagId of s.transactionsSettings.tags) {
              if (s.transactions[transactionId].tags.includes(tagId)) { return true }
            }
            return false
          }

          transactionIds = transactionIds.filter(transactionsId => {
            const transaction = s.transactions[transactionsId]

            return (
              (!s.transactionsSettings.dateStart || transaction.date >= new Date(s.transactionsSettings.dateStart))
              && (!s.transactionsSettings.dateEnd || transaction.date < new Date(new Date(s.transactionsSettings.dateEnd).getFullYear(), new Date(s.transactionsSettings.dateEnd).getMonth(), new Date(s.transactionsSettings.dateEnd).getDate() + 1))
              && (s.transactionsSettings.sumStart === null || transaction.sum >= (s.transactionsSettings.sumStart * 100))
              && (s.transactionsSettings.sumEnd === null || transaction.sum <= (s.transactionsSettings.sumEnd * 100))
              && (!s.transactionsSettings.accountId || transaction.accountId === s.transactionsSettings.accountId)
              && (!s.transactionsSettings.tags.length || (transaction.tags && transaction.tags.length && containaAnyTag(transactionsId)
              ))
            )
          })
        }

        if (getters.transactionsFiltered) {
          const containsAllTags = (transactionId) => {
            for (const tagId of s.transactionsFilters.tags) {
              if (!s.transactions[transactionId].tags.includes(tagId)) { return false }
            }
            return true
          }

          const containaAnyTag = (transactionId) => {
            for (const tagId of s.transactionsFilters.tags) {
              if (s.transactions[transactionId].tags.includes(tagId)) { return true }
            }
            return false
          }

          const anyTagContainsSearchStr = (tagsArr) => {
            if (!tagsArr) { return false }
            for (const tagId of tagsArr) {
              if (getters.tags[tagId] && getters.tags[tagId].name && getters.tags[tagId].name.toLowerCase().includes(s.transactionsFilters.searchStr.toLowerCase())) { return true }
            }
            return false
          }

          const currencyNameContainsSearchStr = (currencyObj) => {
            if (!currencyObj) { return false }
            for (const languageId of Object.keys(currencyObj)) {
              if (currencyObj[languageId] && currencyObj[languageId].toLowerCase().includes(s.transactionsFilters.searchStr.toLowerCase())) { return true }
            }
            return false
          }

          transactionIds = transactionIds.filter(transactionsId => {
            const transaction = s.transactions[transactionsId]

            return (
              (!s.transactionsFilters.dateStart || transaction.date >= new Date(s.transactionsFilters.dateStart)) &&
              (!s.transactionsFilters.dateEnd || transaction.date < new Date(new Date(s.transactionsFilters.dateEnd).getFullYear(), new Date(s.transactionsFilters.dateEnd).getMonth(), new Date(s.transactionsFilters.dateEnd).getDate() + 1)) &&
              (s.transactionsFilters.sumStart === null || transaction.sum >= (s.transactionsFilters.sumStart * 100)) &&
              (s.transactionsFilters.sumEnd === null || transaction.sum <= (s.transactionsFilters.sumEnd * 100)) &&
              (s.transactionsFilters.bankId === null || (s.transactionsFilters.bankId === 'nobank' && !getters.accounts[transaction.accountId].bankId) || (s.transactionsFilters.bankId === getters.accounts[transaction.accountId].bankId)) &&
              (!s.transactionsFilters.accountId || transaction.accountId === s.transactionsFilters.accountId) &&
              (!s.transactionsFilters.cardId || transaction.cardId === s.transactionsFilters.cardId) &&
              (!s.transactionsFilters.currencyCode || getters.accounts[transaction.accountId].currencyCode === s.transactionsFilters.currencyCode) &&
              (!s.transactionsFilters.searchStr ||
                (
                  (transaction.comment && transaction.comment.toLowerCase().includes(s.transactionsFilters.searchStr.toLowerCase()))
                  || (transaction.tags && transaction.tags.length && anyTagContainsSearchStr(transaction.tags))
                  || (transaction.cardId && getters.cards[transaction.cardId] && getters.cards[transaction.cardId].name && getters.cards[transaction.cardId].name.toLowerCase().includes(s.transactionsFilters.searchStr.toLowerCase()))
                  || (
                    transaction.accountId && getters.accounts[transaction.accountId]
                    && (
                      (getters.accounts[transaction.accountId].name && getters.accounts[transaction.accountId].name.toLowerCase().includes(s.transactionsFilters.searchStr.toLowerCase()))
                      || (getters.accounts[transaction.accountId].bankId && getters.banks[getters.accounts[transaction.accountId].bankId] && getters.banks[getters.accounts[transaction.accountId].bankId].name && getters.banks[getters.accounts[transaction.accountId].bankId].name.toLowerCase().includes(s.transactionsFilters.searchStr.toLowerCase()))
                      || (getters.accounts[transaction.accountId].currencyCode && (
                        (getters.accounts[transaction.accountId].currencyCode.toLowerCase().includes(s.transactionsFilters.searchStr.toLowerCase()))
                        || (Currencies[getters.accounts[transaction.accountId].currencyCode] && Currencies[getters.accounts[transaction.accountId].currencyCode].locales && currencyNameContainsSearchStr(Currencies[getters.accounts[transaction.accountId].currencyCode].locales))
                      ))
                    )
                  )
                )
              ) &&
              (!s.transactionsFilters.noTags || !transaction.tags || !transaction.tags.length) &&
              (!s.transactionsFilters.tags.length || (transaction.tags && transaction.tags.length &&
                ((s.transactionsFilters.useTags === 'all' && containsAllTags(transactionsId)) ||
                  (s.transactionsFilters.useTags === 'any' && containaAnyTag(transactionsId)))
              ))
            )
          })
        }
      }

      if (transactionIds.length > 1) {
        const sortParameter = s.transactionsSort.field
        const sortType = s.transactionsSort.direction

        if (sortParameter === 'Date' || !sortParameter) {
          transactionIds.sort((a, b) => {
            const transactionA = s.transactions[a]
            const transactionB = s.transactions[b]
            if (+transactionA.date === +transactionB.date) {
              if (+transactionA.timestamp === +transactionB.timestamp) {
                if (a < b) { return -1 }
                if (a > b) { return 1 }
                return 0
              } else {
                return +transactionA.timestamp - +transactionB.timestamp
              }
            } else {
              return +transactionA.date - +transactionB.date
            }
          })
        } else if (sortParameter === 'Account') {
          transactionIds.sort((a, b) => {
            const transactionA = s.transactions[a]
            const transactionB = s.transactions[b]
            if (transactionA.accountId) {
              transactionA.accountName = getters.accounts[transactionA.accountId].name
            } else {
              transactionA.accountName = ''
            }

            if (transactionB.accountId) {
              transactionB.accountName = getters.accounts[transactionB.accountId].name
            } else {
              transactionB.accountName = ''
            }

            if (transactionA.accountName === transactionB.accountName) {
              if (+transactionA.date === +transactionB.date) {
                if (+transactionA.timestamp === +transactionB.timestamp) {
                  if (a < b) { return -1 }
                  if (a > b) { return 1 }
                  return 0
                } else {
                  return +transactionA.timestamp - +transactionB.timestamp
                }
              } else {
                return +transactionA.date - +transactionB.date
              }
            } else {
              if (transactionA.accountName < transactionB.accountName) { return -1 }
              if (transactionA.accountName > transactionB.accountName) { return 1 }
              return 0
            }
          })
        } else if (sortParameter === 'Tags') {
          transactionIds.sort((a, b) => {
            const transactionA = s.transactions[a]
            const transactionB = s.transactions[b]
            if (!transactionA.tags) {
              transactionA.tags = []
            }

            if (!transactionB.tags) {
              transactionB.tags = []
            }

            return transactionA.tags.length - transactionB.tags.length
          })
        } else {
          transactionIds.sort((a, b) => {
            const transactionA = s.transactions[a]
            const transactionB = s.transactions[b]
            if (!transactionA[sortParameter.toLowerCase()]) {
              transactionA[sortParameter.toLowerCase()] = ''
            }

            if (!transactionB[sortParameter.toLowerCase()]) {
              transactionB[sortParameter.toLowerCase()] = ''
            }

            if (transactionA[sortParameter.toLowerCase()] === transactionB[sortParameter.toLowerCase()]) {
              if (+transactionA.date === +transactionB.date) {
                if (+transactionA.timestamp === +transactionB.timestamp) {
                  if (a < b) { return -1 }
                  if (a > b) { return 1 }
                  return 0
                } else {
                  return +transactionA.timestamp - +transactionB.timestamp
                }
              } else {
                return +transactionA.date - +transactionB.date
              }
            } else {
              if (transactionA[sortParameter.toLowerCase()] < transactionB[sortParameter.toLowerCase()]) { return -1 }
              if (transactionA[sortParameter.toLowerCase()] > transactionB[sortParameter.toLowerCase()]) { return 1 }
              return 0
            }
          })
        }

        if (sortType === 'desc') { transactionIds.reverse() }
      }

      return transactionIds
    },
    transactionsFilters: s => s.transactionsFilters,
    transactionsFiltered: s => {
      if (
        s.transactionsFilters.dateStart
        || s.transactionsFilters.dateEnd
        || s.transactionsFilters.bankId
        || s.transactionsFilters.accountId
        || s.transactionsFilters.cardId
        || s.transactionsFilters.currencyCode
        || s.transactionsFilters.sumStart !== null
        || s.transactionsFilters.sumEnd !== null
        || s.transactionsFilters.tags.length
        || s.transactionsFilters.noTags
        || s.transactionsFilters.searchStr
      ) {
        return true
      } else {
        return false
      }
    },
    transactionsSettings: s => s.transactionsSettings,
    transactionsSettingsFiltered: s => {
      if (s.transactionsSettings.dateStart ||
        s.transactionsSettings.dateEnd ||
        s.transactionsSettings.sumStart ||
        s.transactionsSettings.sumEnd ||
        s.transactionsSettings.accountId ||
        s.transactionsSettings.tags.length
      ) {
        return true
      } else {
        return false
      }
    },
    transactionsSort: s => s.transactionsSort,
    lastFetchedTransaction: s => s.lastFetchedTransaction,
    transactionsMinDate: s => s.transactionsMinDate,
    transactionsMaxDate: s => s.transactionsMaxDate,
    fetchedTransactionsMinMaxDate: (s) => {
      if (s.transactionsReset) {
        //
      }

      const transactionIds = Object.keys(s.transactions)

      transactionIds.sort((a, b) => {
        const transactionA = s.transactions[a]
        const transactionB = s.transactions[b]
        if (+transactionA.date < +transactionB.date) { return -1 }
        if (transactionB.date > transactionA.date) { return 1 }
        return 0
      })

      return [s.transactions[transactionIds[0]].date, s.transactions[transactionIds[transactionIds.length - 1]].date]
    },
    totalTransactionsSums: (s, getters) => {
      if (s.transactionsReset) {
        //
      }

      const accounts = getters.accounts
      const filteredTransactions = getters.filteredTransactions

      let currencyCodes = []

      for (const transactionId of filteredTransactions) {
        const transaction = s.transactions[transactionId]
        const currencyCode = accounts[transaction.accountId].currencyCode

        if (!currencyCodes.includes(currencyCode)) {
          if (getters.defaultCurrency && currencyCode === getters.defaultCurrency) {
            currencyCodes.unshift(currencyCode)
          } else {
            currencyCodes.push(currencyCode)
          }
        }
      }

      let totalSums = {}

      for (const currrencyCideIdx in currencyCodes) {
        const currencyCode = currencyCodes[currrencyCideIdx]
        totalSums[currencyCode] = 0

        const transactionsForCurrency = filteredTransactions.filter(transactionId => {
          const transaction = s.transactions[transactionId]
          const transactionCurrencyCode = accounts[transaction.accountId].currencyCode
          return transactionCurrencyCode === currencyCode
        })

        for (const transactionId of transactionsForCurrency) {
          const transaction = s.transactions[transactionId]
          totalSums[currencyCode] = totalSums[currencyCode] + transaction.sum
        }
      }

      return totalSums
    },
    transactionsByAccountIds: (s, getters) => {
      const accountIds = []

      const transactionsIds = Object.keys(getters.transactions)

      for (const transactionId of transactionsIds) {
        const transaction = getters.transactions[transactionId]
        const accountId = transaction.accountId

        if (!accountIds.includes(accountId)) {
          accountIds.push(accountId)
        }
      }

      return accountIds
    },
    transactionsShow: s => s.transactionsShow,
    transactionsFilteredBySections: (s, getters) => {
      return {
        'Date': !!(getters.transactionsFilters.dateStart || getters.transactionsFilters.dateEnd),
        'Sum': !!(getters.transactionsFilters.sumStart || getters.transactionsFilters.sumEnd),
        'Account': !!(getters.transactionsFilters.bankId || getters.transactionsFilters.accountId || getters.transactionsFilters.cardId || getters.transactionsFilters.currencyCode),
        'Tags': !!(getters.transactionsFilters.tags.length || getters.transactionsFilters.noTags),
      }
    },
    transactionsShowOpened: (s, getters) => {
      for (const key of Object.keys(getters.transactionsShow)) {
        if (getters.transactionsShow[key]) { return true }
      }
      return false
    },
    deletingTransactions: s => s.deletingTransactions
  }
}